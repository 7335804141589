.select-dropdown {
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.select-dropdown .placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.select-dropdown .selected-value {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.select-dropdown .selected-value.row-card {
  border: none;
  background-color: transparent;
}

.row-card {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  margin-bottom: 3px;
  border: 1px solid transparent;
  font-size: 12px;
}

.row-card:hover {
  background-color: #e9ecef;
  border: 1px solid transparent;
}

.row-card .img-cont {
  width: 35px;
  margin-right: 10px;
}

.row-card .card-brand {
  margin-right: 15px;
}

.row-card .card-number-masked {
  margin-right: 25px;
}

.row-card.empty {
  color: #ccc;
  background-color: transparent;
  cursor: default;
}

.row-card.empty:hover {
  background-color: transparent;
  cursor: default;
}
