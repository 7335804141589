#preview-container #previews-tabs.tabs-hidden .nav-tabs {
  display: none;
}

.preview-card {
  border: 5px solid #ddd;
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.preview-card.not-rounded {
  border-radius: 0;
  border-width: 3px;
}

.preview-card .full-w {
  width: 90%;
}

.preview-card .logo {
  height: 50px;
  margin-bottom: 20px;
}

.preview-card .logo img {
  max-height: 50px;
}

.preview-card .bar {
  display: flex;
  width: 250px;
  justify-content: space-around;
  margin-bottom: 20px;
}

.preview-card .bar .bar-left,
.preview-card .bar .bar-right {
  border-radius: 5px;
  position: relative;
  display: block;
  height: 5px;
  width: 48%;
  background-color: #eee;
}

.preview-card .bar .bar-left {
  /* background-color: #10a0e3; */
}

.preview-card .header-paypoint {
  text-align: center;
}

.preview-card .header-paypoint h5 {
  margin-bottom: 3px;
}

.preview-card .header-paypoint {
  margin-bottom: 20px;
}

.preview-card .invoice-data {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 80%;
}

.preview-card .invoice-data.center {
  justify-content: center;
}

.preview-card .invoice-data .group-data {
  flex: 0 1 106px;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  margin-right: 20px;
  margin-left: 20px;
}

.preview-card .invoice-data .group-data.middle {
  text-align: center;
}

.preview-card .invoice-data .group-data.right {
  text-align: right;
}

.preview-card .invoice-data .group-header {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 3px;
}

.preview-card .action-invoice-details {
  color: #10a0e3;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
}

.preview-card .invoice-details {
  margin-top: 20px;
}

.preview-card .invoice-details .header {
  display: flex;
}

.preview-card .invoice-details .header .col {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

.preview-card .invoice-details .col.product {
  text-align: left;
}

.preview-card .invoice-details .col.unit {
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 70px;
  margin-left: 15px;
}

.preview-card .invoice-details .col.qty {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60px;
  text-align: center;
  margin-left: 15px;
}

.preview-card .invoice-details .col.amount {
  text-align: right;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 15px;
  flex-basis: 100px;
}

.preview-card .invoice-details .items-body {
  margin-top: 10px;
  font-size: 12px;
}

.preview-card .invoice-details .items-body .row-item {
  position: relative;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.preview-card .invoice-details .items-body .row-item:last-child {
  border-bottom: none;
}

.preview-card .invoice-details .items-body .row-item .details {
  display: flex;
}

.preview-card .invoice-details .items-body .row-item .description {
  font-size: 11px;
}

.preview-card .invoice-details table .description {
  font-size: 11px;
  padding: 4px 0;
  font-weight: normal;
}

.preview-card .review-total {
  margin-top: 20px;
}

.preview-card .review-total {
  margin-top: 20px;
}

.preview-card .btn-pay {
  margin-top: 20px;
}

.preview-card .contact-us {
  margin-top: 20px;
}

.preview-card .memo-note {
  margin-top: 20px;
  display: block;
  text-align: center;
  font-size: 12px;
}

.preview-card .card-brands {
  background-color: white;
}

.preview-card .payment-method {
  margin-top: 20px;
  margin-bottom: 10px;
}

.preview-card .btn-pay button svg {
  height: 25px;
  width: 25px;
  margin-right: 5px;
  position: relative;
  bottom: 2px;
}

#preview-paylink {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

#preview-email {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

/* PREVIEW INVOICE PDF */

#preview-invoice {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

#preview-invoice section {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

#preview-invoice section.header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #eee;
}

#preview-invoice .header .logo {
  height: 50px;
  min-width: 50px;
  margin-bottom: 15px;
}

#preview-invoice .header .logo img {
  max-height: 100%;
}

#preview-invoice .header .welcome {
  font-size: 18px;
  font-weight: bold;
}

#preview-invoice section.information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

#preview-invoice .information .number {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

#preview-invoice .information .data {
  flex-shrink: 1;
  display: flex;
  /* width: 50%; */
  position: relative;
  top: -2px;
}

#preview-invoice .information .data > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #eee;
  border-top-width: 0px;
  display: block;
  border-right-width: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

#preview-invoice .information .data > div:last-child {
  border-right-width: 2px;
}

#preview-invoice .information .data .title {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#preview-invoice .information .data .value {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

#preview-invoice .information .data .pay {
  /* background-color: orange; */
  color: white;
}

#preview-invoice section.billing-info {
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

#preview-invoice section.billing-info .column {
  padding-right: 25px;
}

#preview-invoice section.billing-info .column:last-child {
  padding-right: 0;
}

#preview-invoice .billing-info span {
  display: block;
}

#preview-invoice .billing-info span.title {
  margin-bottom: 15px;
}

#preview-invoice .items .row-item {
  border-bottom: 1px solid #eee;
  border-top: none;
}

#preview-invoice .items .row-item.skeleton .col{
  color: #eee;
  background-color: #eee;
}

#preview-invoice .items table .skeleton td{
  background-color: #eee;
  border: solid 8px #ffffff;
}

#preview-invoice section.items {
  margin-bottom: 200px;
}

#preview-invoice .items .row-item:first-child, #preview-invoice .items .table tr{
  border-top: 1px solid #eee;
}

#preview-invoice .items .table tr:first-child{
  border-top: none;
}

#preview-invoice .items .row-item .product, #preview-invoice .items .table .product {
  font-weight: bold;
}

#preview-invoice .items .row-item .product, #preview-invoice .items .table td {
  padding: 6px 4px;
}

#preview-invoice .items .row-item.state {
  width: 200px;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
}

#preview-invoice .items .row-item.state:last-child {
  border-bottom: none;
  font-weight: bold;
}

#preview-invoice section.memo-note {
  font-size: 12px;
  margin-bottom: 15px;
  text-align: left;
}

#preview-invoice section.footer .text {
  width: 100%;
  display: block;
  border-top: 1px solid #eee;
  padding-top: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

#preview-invoice #paid-tag, #preview-invoice #partially-paid-tag {
  position: absolute;
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  padding: 20px 30px;
  top: 30%;
  right: 50%;
  color: #88c952;
  border-radius: 10px;
  border: 4px solid #88c952;
  z-index: 1;
  transform: translateX(50%) rotate(-20deg);
}

#preview-invoice #partially-paid-tag {
  color: #ffcd84;
  border: 4px solid #ffcd84;
  text-align: center;
}

#preview-invoice #paid-tag::after, #preview-invoice #partially-paid-tag::after {
  display: none;
}

@media (max-width: 575px) { 
  #preview-invoice .header .logo{
    text-align: center;
  }
  
  #preview-invoice .header .welcome{
    text-align: center;
    margin: 10px 0 20px 0;
  }

  #preview-invoice section.information{
    display: block;
  }

  #preview-invoice .information .number{
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  
  #preview-invoice .information .data{
    display: block;
    width: 100%;
  }

  #preview-invoice .information .data > div{
    border-right-width: 2px;
    border-top-width: 2px;
    margin-bottom: 10px;
  }

  #preview-invoice .items .row-item.state{
    width: 100%;
  }
}
