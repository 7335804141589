#dropdown-invoices .dropdown-item .circle {
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.main-top-bar .top-bar-sub a{
  padding: 0;
  margin: 0 8px;
}

.main-top-bar .top-bar-sub div.btn-action-invoice-builder a{
  padding: 4px 8px;
  margin: 0;
}

.dropdown-item .outstanding {
  background-color: rgb(252, 229, 152);
}

.dropdown-item .past-due {
  background-color: rgb(248, 147, 147);
}

#invoice-settings .icheck-primary {
  margin-top: 10px !important;
}

#invoice-settings .input-by-radio {
  padding-left: 30px !important;
}

.form-floating {
  position: relative;
}

#invoicesBuilder .form-floating .form-control {
  padding-right: 35px;
}

#invoicesBuilder .charge-payment-options .form-floating .form-control {
  padding-right: 0;
}

.form-floating .form-control:disabled,
.form-group.disabled svg,
.form-group.disabled label {
  opacity: 1;
}

.form-floating .icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 0;
  transform: translateY(50%) !important;
}

.form-floating .icon svg {
  height: 20px;
  width: 20px;
}

tbody.report-invoices .customer {
  display: flex;
  align-items: center;
}

tbody.report-invoices .customer img {
  width: 35px;
  height: 35px;
  border: 1px solid #d2d2d2;
  border-radius: 100%;
  padding: 5px;
}

tbody.report-invoices .customer span {
  display: inline-block;
  margin-left: 10px;
}

tbody td {
  vertical-align: middle;
  height: 55px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

tbody .badge.bg-light {
  background-color: #e9e9ea !important;
}

tbody.report-invoices a {
  text-decoration: none;
  color: #212529;
}

#popover-help-prefix.bs-popover-bottom,
#popover-help-prefix-number.bs-popover-bottom {
  max-width: 350px;
  min-width: auto;
}

#popover-help-prefix.bs-popover-bottom .popover-body,
#popover-help-prefix-number.bs-popover-bottom .popover-body {
  min-width: auto;
}

/* Builder */

#wrapper-invoice-form {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  #wrapper-invoice-form {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1440px) {
  #wrapper-invoice-form {
    width: 85%;
  }
}

#invoicesBuilder .body-builder {
  padding: 6rem 0 0;
}
#invoicesBuilder .body-builder.disabled-form input,
#invoicesBuilder .body-builder.disabled-form select{
  color: #000000;
  opacity: 1;
}
#invoicesBuilder .body-builder.disabled-form input[type="radio"],
#invoicesBuilder .body-builder.disabled-form input[type="checkbox"]{
  opacity: 0;
}
#invoicesBuilder .wrapper-form {
  margin-top: 40px;
}
#invoicesBuilder .section-form {
  margin-top: 30px;
  margin-bottom: 30px;
}

#invoicesBuilder .row-schedule {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#invoicesBuilder .row-schedule a {
  text-decoration: none;
}

#invoicesBuilder .row-fields-schedule {
  margin-top: 10px;
}

#invoicesBuilder input:disabled {
  background-color: #eee;
}

/* Builder Items Table */
#item-table {
  margin-top: 30px;
}

#item-table .p-right {
  padding-right: 25px;
}

#item-table .header {
  display: flex;
  margin-bottom: 15px;
}

#item-table .header .col {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: #999;
}

#item-table .header .col.product,
#item-table .body .col.product {
  flex-grow: 2;
  text-align: left !important;
}

#item-table .product,
#item-table .unit-price,
#item-table .qty-value {
  cursor: pointer;
}

#item-table .row-item {
  position: relative;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

#item-table .row-item .underline {
  border-bottom: 1px solid #eee;
  padding-top: 10px;
}

#item-table .row-item .details {
  display: flex;
}

#item-table .row-item .details .col {
  text-align: center;
}

#item-table .col.amount {
  text-align: right !important;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 10px;
  flex-basis: 65px;
}

#item-table .col.qty {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 85px;
}

#item-table .row-item .details .btn {
  border-radius: 50%;
}

#item-table .row-item .details .qty-value {
  width: 18px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
  margin-left: 4px;
}

#item-table .row-item .description {
  font-size: 12px;
  font-weight: normal;
  margin-top: 5px;
}

#item-table .row-item .category-icon {
  display: block;
  position: absolute;
  right: -5px;
  top: 10px;
}

#item-table .row-item:hover .category-icon {
  display: block;
}

#item-table .row-subtotal {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 8px;
}

#item-table .row-total {
  font-weight: bold;
}

#item-table .row-total,
#item-table .row-subtotal .item,
#item-table .row-subtotal .underline {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-left: 60%;
  padding-top: 8px;
  padding-bottom: 8px;
}

#item-table .row-subtotal .item {
  padding: 0;
  margin-bottom: 10px;
}

#item-table .row-subtotal .underline {
  border-bottom: 1px solid #eee;
  padding-bottom: 0;
}

#item-table .row-subtotal .item:nth-last-child(2) {
  margin-bottom: 0;
}

#item-table .row-subtotal .item .text {
  font-size: 12px;
}

#advanced-options .card {
  border: none;
  padding: 0;
}

#advanced-options .card-body {
  padding-left: 0;
}

#advanced-options .card-body h6 {
  user-select: none;
  cursor: pointer;
}

#advanced-options .card-body h6 svg {
  height: 25px;
  width: 25px;
  margin-left: 5px;
}

#advanced-options .options {
  padding-left: 20px;
}

#advanced-options .option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  user-select: none;
}

#advanced-options .option svg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.popover-advanced-options .popover-footer {
  border-top: none;
}

.popover-advanced-options .popover-footer .btn-success {
  margin-left: 15px;
}

.popover-advanced-options .btn-success {
  background: #10a0e3;
  color: #fff;
  border-color: none;
}

.payment-option {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
}

.payment-option.with-separator {
  border-bottom: 1px solid #eee;
}

.payment-option .radio-selection,
.payment-option .radio-description .icon {
  display: flex;
  align-items: center;
}

.payment-option .radio-description {
  display: flex;
  padding-left: 0px;
  width: 100%;
}

.payment-option .radio-description .icon svg {
  width: 30px;
  height: 30px;
}

.payment-option .radio-description .headline {
  font-size: 14px;
  font-weight: bold;
}

.payment-option .radio-description .info {
  padding-left: 15px;
  width: 100%;
}

.payment-option .radio-description .headline {
  font-size: 14px;
  font-weight: bold;
}

.payment-option .radio-description .subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-option .radio-description .subtitle .text {
  flex-shrink: 1;
  font-size: 12px;
  padding-right: 15px;
  color: #999;
}

.payment-option .radio-description .subtitle svg {
  cursor: pointer;
  fill: #10a0e3;
  height: 17px;
  width: 17px;
}

.payment-option .dropdown-toggle {
  padding: 0;
}

.payment-option .dropdown-toggle:focus {
  box-shadow: none;
}

.payment-option .dropdown-toggle::after {
  display: none;
}

.payment-option .dropdown-item {
  font-size: 12px;
  cursor: pointer;
}

.charge-payment-options {
  padding-left: 32px;
  margin-top: 10px;
}

.charge-payment-options .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  font-size: 12px;
  border: none;
}

.charge-payment-options .nav-tabs .nav-item {
  padding: 0;
  padding-bottom: 5px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  color: inherit;
}

.charge-payment-options .nav-tabs .nav-item.active {
  /* font-weight: bold; */
  border-bottom: 3px solid #10a0e3;
}

.charge-payment-options .nav-tabs .nav-item:first-child {
  margin-right: 15px;
}

.charge-payment-options .nav-tabs .nav-item:last-child {
  margin-left: 15px;
}

.charge-payment-options .card-accepted {
  position: relative;
  margin-top: 15px;
}

.charge-payment-options .card-accepted img {
  height: 11px;
  margin-right: 6px;
  display: inline-block;
}

.charge-payment-options .card-accepted img.amex {
  height: 14px;
}

.charge-payment-options .card-accepted img.discover {
  height: 13px;
}

.charge-payment-options .card-accepted img.mastercard {
  height: 15px;
}

/* PREVIEW */

#wrapper-invoice-form,
#wrapper-bill-preview,
#wrapper-invoice-preview {
  padding-top: 40px;
}

#wrapper-bill-preview{
  margin-right: -44px;
  margin-top: -26px;
}

#wrapper-bill-preview.codeBg{
  background-color: #212529;
}

#wrapper-bill-preview .error{
  font-size: 12px;
  font-weight: normal;
}

#wrapper-bill-preview pre{
  margin: 30px;
}

#wrapper-bill-preview .buttonOnPreview{
  position: absolute;
  background-color: rgb(9,128,196);
  text-align: center;
  font-size: 26px;
  color: #ffffff!important;
  border-radius: 5px;
  border: 0;
  padding: 2px 14px;
  -webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.6); 
  box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.6);
  right: 44px;
  top: 120px;
  cursor: pointer;
}


#wrapper-invoice-preview, #wrapper-bill-preview {
  position: relative;
  background-color: #f8f9fa;
  display: block;
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
  border-left: 1px solid #e6e6e6;
  /* z-index: 10; */
}

#wrapper-invoice-preview ::after {
  position: fixed;
  content: '';
  width: 94px;
  height: 100%;
  background-color: #f8f9fa;
  z-index: 1;
  right: -50px;
  top: 0;
  z-index: 0;
}

#wrapper-invoice-preview.wrapper-invoice-preview-clean{
  margin-left: -45px;
  padding-left: 45px;
  border:none;
}

#preview-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: block;
  z-index: 1;
}

@media only screen and (min-width: 1260px) {
  #preview-container {
    width: 450px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1600px) {
  #preview-container {
    width: 650px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

#preview-container .subtitle {
  display: block;
  width: 100%;
  font-size: 12px;
}

#previews-tabs {
  margin-top: 20px;
}

#previews-tabs .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  font-size: 12px;
  border: none;
  justify-content: center;
}

#previews-tabs .nav-tabs .nav-item {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
  border: none;
  cursor: pointer;
  color: inherit;
}

#previews-tabs .nav-tabs .nav-item.active {
  color: #10a0e3;
  border-bottom: 3px solid #10a0e3;
  background-color: #f8f9fa;
}

#payment-terms {
  display: flex;
  align-items: center;
}

#payment-terms .icon-settings {
  margin-left: 10px;
}

#payment-terms .icon-settings svg {
  cursor: pointer;
  fill: #10a0e3;
  height: 17px;
  width: 17px;
}

#payment-terms .dropdown-toggle {
  padding-right: 0;
}

#payment-terms .dropdown-toggle:focus {
  box-shadow: none;
}

#payment-terms .dropdown-toggle::after {
  display: none;
}

#payment-terms .dropdown-item {
  font-size: 12px;
  cursor: pointer;
}

#btn-action-invoice-builder:disabled, .btn-action-bill-builder:disabled, .blue-dropdown-button button:disabled{
  opacity: 0.3;
}

#modal-confirmation-buttons {
  display: flex;
  justify-content: end;
}

#modal-confirmation-buttons button {
  margin-left: 25px;
}

#modal-confirmation-buttons button.btn-main svg {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

#modal-confirmation .description {
  font-size: 16px;
}

#modal-confirmation .underlined {
  border-bottom: 1px solid #eee;
}

@media (max-width: 1610px) { 

  #invoicesBuilder .card-body .card-brands{
    position: revert;
  }

  #invoicesBuilder .card-body .card-brands img:first-child{
    margin-left: 0;
  }
  
}

@media (max-width: 1260px) { 
  #wrapper-invoice-preview ::after{
    display: none;
  }
  #wrapper-invoice-preview{
    border-left: none;
    background-color: #ffffff;
  }

  #preview-container{
    padding-left: 0;
    padding-right: 0;
    background-color: #ffffff;
  }

  #invoicesBuilder .card-body .card-brands{
    position: absolute;
  }

  #invoicesBuilder .card-body .card-brands img:first-child{
    margin-left: 6px;
  }

  #wrapper-bill-preview ::after{
    display: none;
  }
  #wrapper-bill-preview{
    border-left: none;
    background-color: #ffffff;
    padding-right: 44px;
  }
}

@media (max-width: 586px) { 

  #invoicesBuilder .card-body .card-brands{
    position: revert;
  }

  #invoicesBuilder .card-body .card-brands img:first-child{
    margin-left: 0;
  }

  .charge-payment-options{
    padding-left: 0;
  }

  #wrapper-bill-preview{
    padding: 0;
    min-height: 500px;
    margin: 40px 0;
  }
  
  #wrapper-bill-preview .addInvoice{
      top: 20%
  }
  
}

