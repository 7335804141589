/**
 * Main wrapper
 */
 .select-search {
    position: relative;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #ced4da;
}
.select-search__input {
    display: block;
    height: 45px;
    width: 100%;
    padding: 0 40px 0 16px;
    background-color: transparent;
    border: 1px solid #ced4da;
    color: #212529;
    border-radius: .25rem;
    text-align: left;
    line-height: 1.5;
    font-weight: 400!important;
    font-size: 1rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-size: 16px 12px;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    padding-top: 1rem;
    padding-bottom: .625rem;

}

.input-error .select-search__input{
    border-color: #dc3545;
}

.input-error .select-search__input:focus{
    border-color: #dc3545;
    box-shadow: 0 0 0 5px rgb(220 53 69 / 10%);
}

.select-search__input__label{
    position: absolute;
    top: 2px;
   
    opacity: .65;
    transform: scale(.75) translateY(-3px) translateX(-18px);
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    
    border: 1px solid #ced4da;
    
}

.select-search__select ul{
    padding: 0;
    margin: 0;
    font-size: 11px;
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    width: 100%;
    padding: 10px 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 12px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #e9ecef;
    color: #333333;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #e9ecef;
    color: #333333;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 9;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.modal-body .select-search__input {
    height: 33px;
    padding: 8px 0.75rem 0 0.75rem;
    font-size: 13px;
}

.selectSearchPT0 .select-search__input{
    padding-top: 0;
}

.modal-body .select-search__select {
    top: 33px!important;
}