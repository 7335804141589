/*========  body ====================*/
.btn-panels {
	position: sticky;
	background: #ffffff;
	z-index: 2;
	top: 105px;
	min-height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
	/* padding-top: 30px; */
	padding-top: 10px;
	/* padding-bottom: 30px; */
	padding-bottom: 10px;
	text-align: center;
	/* user-select: none; */
	font-size: 12px;
}
.btn-panels-125 {
	position: sticky;
	background: #ffffff;
	z-index: 2;
	top: 125px;
	min-height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
	/* padding-top: 30px; */
	padding-top: 10px;
	/* padding-bottom: 30px; */
	padding-bottom: 10px;
	text-align: center;
	/* user-select: none; */
	font-size: 12px;
}
.btn-panels.services-moneyIn {
	top: 165px;
	padding-top: 10px;
}
.btn-panels.services-moneyOut {
	top: 165px;
	padding-top: 10px;
}
.container-btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	margin-left: 0;
}
.btn-tabs {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	margin-top: 10px;
	padding: 0 5px;
	width: 150px;
	min-height: 67px;
	color: #000000;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
}
.btn-tabs .icon {
	font-size: 20px;
	margin-bottom: 5px;
}
.btn-tabs:hover {
	font-weight: 600;
	color: #03a9f4 !important;
	transform: scale(1.05);
}
.btn-tabs.active {
	font-weight: 600;
	color: #03a9f4 !important;
	transform: scale(1.05);
}
.btn-small {
	height: 27.6px;
}
.cards-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 130px;
	gap: 40px;
}
.cards-container-hellosing {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 0px;
	gap: 40px;
}
/*============ Form ================*/
.cards-details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 24px 32px 32px 32px;
	gap: 16px;
	max-width: 988px;
	width: 100%;
	background: #ffffff;
	/*box-shadow: 1px -1px 16px rgb(0 0 0 / 38%); 1px -1px 16px rgba(0, 0, 0, 0.05);   */
	/* box-shadow: 0 0 0 12px rgb(0 0 0/5%); */
	box-shadow: 0 0 0 8px rgb(0 0 0/5%);
	border-radius: 8px;
	border-color: rgb(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 0, 0, 0.125);
	/* user-select: none; */
}
.cards-details-hs {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 24px 32px 32px 32px;
	gap: 16px;
	max-width: 1200px;
	width: 100%;
	background: #ffffff;
	/*box-shadow: 1px -1px 16px rgb(0 0 0 / 38%); 1px -1px 16px rgba(0, 0, 0, 0.05);   */
	/* box-shadow: 0 0 0 12px rgb(0 0 0/5%); */
	box-shadow: 0 0 0 8px rgb(0 0 0/5%);
	border-radius: 8px;
	border-color: rgb(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 0, 0, 0.125);
	/* user-select: none; */
}
.grid-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0px;
	width: 100%;
}
.grid-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;
	padding: 0px 0px 12px;
}
/*============ Components ================*/
.title-status-bar {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	color: #212121;
	padding-left: 10px;
	padding-right: 10px;
	min-height: 33px;
}
.sub-title-status-bar {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 12.25px;
	letter-spacing: 0.0015em;
	color: #606060;
	padding-left: 10px;
	padding-right: 10px;
}
/*=====================================================*/
.label-form {
	font-family: 'Poppins';
	font-style: normal;
	color: #000a12;
}
.label-links {
	text-decoration: underline;
	color: #10a0e3;
	cursor: pointer;
}
.label-links:hover {
	opacity: 0.6;
}
.label-none-pointer {
	pointer-events: none;
}
.ft-s-20 {
	font-size: 20px;
}
.ft-s-18 {
	font-size: 18px;
}
.ft-s-16 {
	font-size: 16px;
}
.ft-s-14 {
	font-size: 14px !important;
}
.ft-s-13 {
	font-size: 13px;
}
.ft-s-12 {
	font-size: 12px;
}
.ft-s-10 {
	font-size: 10px;
}
.ftn-weight-700 {
	font-weight: 700;
}
.btn {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 15px;
	font-size: 14px;
	gap: 10px;
	border: 3px solid #eaeae9;
	border-radius: 8px;
	text-decoration: none;
	background: transparent;
	color: #000000;
}
.btn-hover-blue:hover {
	color: #008bce;
}
.btn-blue {
	font-weight: 700;
	color: #ffffff;
	background: #03a9f4;
	text-transform: uppercase;
}
.btn-red-letter {
	font-weight: 700;
	color: #ff867c;
	letter-spacing: 0.0015em;
	text-transform: uppercase;
}
.btn-letter-disabled {
	font-weight: 700;
	color: #bababa;
	letter-spacing: 0.0015em;
	text-transform: uppercase;
}
.btn-font-weight {
	font-weight: 700;
	letter-spacing: 0.0015em;
	text-transform: uppercase;
}
.btn-red {
	font-weight: 700;
	color: #eaeae9;
	background: #ff867c;
	text-transform: uppercase;
}
.btn-grey {
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.border-blue {
	font-weight: 700;
	color: #03a9f4;
	border: 3px solid #03a9f4;
	text-transform: uppercase;
}
.border-gray {
	font-weight: 700;
	color: #606060;
	border: 3px solid #eaeae9;
	text-transform: uppercase;
}
.border-disabled {
	font-weight: 700;
	color: #bababa;
	border: 3px solid #bababa;
	text-transform: uppercase;
}
.border-light-gray {
	border: 1px solid #9e9e9e !important;
}
.no-border {
	font-weight: 700;
	border: none;
}
.icon15 {
	font-size: 15px;
}
.icon20 {
	font-size: 20px;
}
.icon24 {
	font-size: 24px;
}
.icon26 {
	font-size: 26px;
}
.icon30 {
	font-size: 30px;
}
.color-gray {
	color: #606060;
}
.color-disabled {
	color: #bababa;
}
.color-blue {
	color: #03a9f4;
}
.color-orange {
	color: #ffa726;
}
.color-red {
	color: #ff867c;
}
.color-error {
	color: #dc3545;
}
.color-teal-light {
	color: #64d8cb;
}
.color-light-green {
	color: #9ccc65;
}
.show-on-hover {
	opacity: 0;
	transition: all 0.7s ease;
}
.show-on-hover-begin:hover .show-on-hover {
	opacity: 1;
}
/* .user-select {
	user-select: none;
} */
.box-shodow-md {
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15),
		0px 2px 11px rgba(44, 43, 42, 0.2);
}
/*============ Components ================*/
.container-status {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;
	margin: auto;
	margin-right: 0;
}
.status-btn-group {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	width: 210px;
	height: 34px;
}
.btn-status {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;
	gap: 8px;
	background: rgba(255, 255, 255, 1e-6);
	border: 1px solid rgba(255, 255, 255, 1e-6);
	color: #03a9f4;
	opacity: 0.5;
}
.btn-status.active {
	opacity: 1;
	background: #03a9f4;
	border: 1px solid #03a9f4;
	color: #ffffff;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-status:hover {
	border: 1px solid #03a9f4;
	opacity: 1;
}
.btn-drop-down-status {
	display: none;
	align-items: center;
	justify-content: center;
	color: #000;
	font-family: 'Poppins';
	font-style: normal;
	letter-spacing: -0.32px;
	background-color: #f9fafb;
	border-color: #f9fafb;
	box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
	border: none;
	border-radius: 0.2rem;
	width: 150px;
	margin-right: 5px;
}
.btn-drop-down-status button {
	font-weight: normal !important;
}
.btn-drop-down-status button::after {
	content: none;
}
.btn-drop-down-status button:focus {
	box-shadow: none;
}
.clear-icon-drop-down button::after {
	content: none;
}
.one-row {
	flex-wrap: nowrap;
}
.width-50 {
	width: 70% !important;
}
.width-60 {
	width: 70% !important;
}
.width-70 {
	width: 70% !important;
}
.textField {
	min-height: 20px;
	width: 226px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 13px !important;
	line-height: 20px;
	letter-spacing: 0.0025em;
	color: rgba(0, 0, 0, 0.8);
	transition: all 0.4s ease;
	margin-right: 10px;
}
.textField.textarea {
	min-height: 88px;
}
.textField.textarea.disabled {
	resize: none;
	overflow: hidden;
	background: transparent;
}
.textField.select-box {
	height: 34px;
	appearance: none;
}
.textField.disabled {
	border: none;
	background: transparent;
}
.textField.disabled.select-box {
	height: 22px;
	appearance: none;
	background: transparent;
	opacity: 1;
}
.showWarning {
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
	border-radius: 0.25rem !important;
	border: 1px solid #dc3545 !important;
	padding-left: 12px;
	padding-right: 12px;
}
.wrapper-width {
	width: 226px;
}
.textField.dataPicker {
	height: 34px;
}
.one-row .textField {
	width: 100%;
}
.input-calendar {
	background-repeat: no-repeat;
	background-image: url('../images/calendar.svg');
	background-size: 22px 22px;
	background-position: right 10px top 5px !important;
}
/*================ SERVICES =================*/
.panel-Services {
	position: sticky;
	top: 100px;
	background: #ffffff;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	max-width: 1108px;
	padding-top: 20px;
	padding-bottom: 10px;
	margin: auto;
	z-index: 2;
}
.container-btn-show-cards {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	letter-spacing: 0.0025em;
	text-transform: uppercase;
	color: #03a9f4 !important;
	padding: 4px 8px;
	gap: 2px;
	width: 100%;
	height: 20px;
	border: 2px solid #03a9f4;
	border-radius: 16px;
	transition: all 0.1s ease-in-out;
}
.container-btn-show-cards.close {
	border: 2px solid rgba(33, 33, 33, 0.2);
	color: rgba(33, 33, 33, 0.5) !important;
}
.container-btn-show-cards.close > :global(svg) {
	transform: rotate(180deg);
}
.container-btn-show-cards:hover {
	background-color: transparent;
	box-shadow: none;
	color: #03a9f4;
}
.container-btn-show-cards.close:hover {
	color: rgba(33, 33, 33, 0.5);
}
.container-btn-show-cards:focus {
	background-color: transparent;
	box-shadow: none;
}
.container-btn-show-cards:active {
	background-color: transparent;
	box-shadow: none;
}
.container-btn-show-cards:active:focus {
	background-color: transparent;
	box-shadow: none;
}
.d-block-1214 {
	display: none;
}
/*-------------------------------- select ----------------------------------*/
.select-input-box-container {
	position: relative;
}
.overflow-hidden {
	overflow: hidden;
}
.select-input-box {
	cursor: pointer;
	/* -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; */
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.select-input-box-opciones {
	position: absolute;
	background: rgb(255, 255, 255);
	border: 1px solid #b1b8be;
	border-radius: 10px;
	box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.16);
	margin-top: 5px;
	padding: 0px;
	overflow: hidden;
	z-index: 100;
	min-width: 226px;
	display: none;
}
.select-input-box-opciones.show {
	display: block;
}
.select-input-box-item-container {
	overflow: auto;
}
.select-input-box-item {
	padding: 0px 5px 0px 0.75rem;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 13px !important;
	letter-spacing: 0.0025em;
}
.select-input-box-item.item-selected {
	background: #999898;
	color: #fff;
}
.select-input-box-item:hover {
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
	background: #666;
	color: #fff;
}
.select-input-box-button-container {
	padding: 5px 0px;
}
/*----------------------------------------------------------------*/
.show-go-paypoint:hover .hide-go-paypoint {
	opacity: 1;
}
.hide-go-paypoint {
	opacity: 0;
	transition: all 0.4s ease;
}
.container-scroll {
	overflow: auto;
	-ms-overflow-style: none;
}
.container-scroll::-webkit-scrollbar {
	width: 0.5em;
}
.container-scroll::-moz-scrollbar {
	width: 0.5em;
}
.container-scroll::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 100vw;
	margin-block: 0.1em;
}
.container-scroll::-webkit-scrollbar-thumb {
	background: #959393;
	border-radius: 100vw;
}
.container-scroll::-webkit-scrollbar-thumb:hover {
	background: #858484;
}
@media screen and (max-width: 1422px) {
	.btn-panels {
		justify-content: space-between !important;
		padding-top: 40px !important;
		padding-bottom: 20px !important;
		min-height: 0 !important;
		margin-right: 10px;
		margin-left: 10px;
	}
	.btn-panels.services-moneyIn .btn-tabs {
		width: 150px !important;
		justify-content: center !important;
	}
	.btn-panels.services-moneyOut .btn-tabs {
		width: 150px !important;
		justify-content: center !important;
	}
	.btn-tabs {
		height: auto !important;
		width: auto !important;
		margin-top: 0 !important;
		min-height: 0 !important;
	}
	.btn-tabs .icon {
		font-size: 32px !important;
	}
	.btn-tabs span {
		display: none !important;
	}
	.cards-container {
		padding-top: 130px !important;
	}
}
@media screen and (max-width: 1240px) {
	.btn-small {
		font-size: 12px;
	}
	.title-status-bar {
		font-size: 16px !important;
		min-height: min-content;
	}
}
@media screen and (max-width: 1214px) {
	.d-none-1214 {
		display: none !important;
	}
	.d-block-1214 {
		display: block !important;
	}
	.grid-container {
		justify-content: flex-start !important;
	}
	.grid-group {
		flex-direction: column !important;
		align-items: flex-start !important;
	}
}
@media screen and (max-width: 1190px) {
	.container-status {
		display: none !important;
	}
	.status-btn-group {
		align-items: center !important;
		justify-content: center !important;
	}
	.btn-drop-down-status {
		display: flex !important;
	}
}
@media screen and (max-width: 1045px) {
	.margin-left-auto {
		margin-left: auto !important;
	}
}
@media screen and (max-width: 997px) {
	.btn-panels.services-moneyIn {
		top: 210px !important;
	}
}
@media screen and (max-width: 959px) {
	.btn-panels.services-moneyIn {
		top: 165px !important;
	}
	.show-on-hover {
		opacity: 1;
	}
	.hide-go-paypoint {
		opacity: 1;
		transition: all 0.4s ease;
	}
}
@media screen and (max-width: 762px) {
	.btn-panels.services-moneyIn {
		top: 210px !important;
	}
}
@media screen and (max-width: 750px) {
	.d-none-750 {
		display: none !important;
	}
	.btn-drop-down-status {
		width: 42px !important;
		margin-right: 10px;
	}
	.icon-status {
		margin: 0 !important;
		margin-left: 5px !important;
	}
	.btn-panels.services-moneyIn {
		top: 165px !important;
	}
}
@media screen and (max-width: 699px) {
	.cards-container.services-moneyOut {
		padding-top: 120px !important;
	}
}
@media screen and (max-width: 680px) {
	.d-block-680 {
		display: block !important;
	}
	.container-btn {
		margin: 0 !important;
	}
	.btn {
		padding: 8px 10px !important;
	}
	.margin-auto {
		margin: auto !important;
	}
}
@media screen and (max-width: 665px) {
	.btn-panels.services-moneyOut {
		top: 195px !important;
	}
}
@media screen and (max-width: 657px) {
	.d-none-657 {
		display: none !important;
	}
}
@media screen and (max-width: 596px) {
	.btn-panels.services-moneyIn {
		top: 210px !important;
	}
}
@media screen and (max-width: 524px) {
	.title-status-bar {
		font-size: 16px !important;
	}
	.sub-title-status-bar {
		text-align: center !important;
	}
	.one-row {
		flex-wrap: wrap !important;
	}
}
@media screen and (max-width: 486px) {
	.btn-panels.services-moneyOut {
		top: 250px !important;
	}
}
@media screen and (max-width: 483px) {
	.ft-s-16 {
		font-size: 13px !important;
	}
	.ft-s-20 {
		font-size: 15px !important;
	}
	.btn {
		font-size: 12px !important;
	}
	.btn-tabs .icon {
		font-size: 25px !important;
	}
	.container-btn-show-cards {
		height: auto !important;
	}
	.columns-sm {
		flex-direction: column !important;
		align-items: flex-start !important;
		gap: 0px !important;
	}
	.btn-panels.services-moneyOut {
		top: 195px !important;
		padding-top: 20px !important;
	}
	.cards-container.services-moneyOut {
		padding-top: 100px !important;
	}
}
@media screen and (max-width: 460px) {
	.rounded-pill-no-label-frame {
		color: transparent !important;
		min-width: 22px !important;
		width: 22px !important;
	}
	.sub-title-status-bar {
		display: none !important;
	}
}
@media screen and (max-width: 440px) {
	.cards-details {
		padding: 20px 10px 32px 10px !important;
	}
	.size-auto {
		width: auto !important;
	}
	.cards-container.services-moneyIn,
	.cards-container.services-moneyOut {
		padding-inline: 0px !important;
	}
}
@media screen and (max-width: 401px) {
	.btn-panels.services-moneyOut {
		top: 225px !important;
	}
}
@media screen and (max-width: 370px) {
	.d-block-370 {
		display: block !important;
	}
	.d-none-370 {
		display: none !important;
	}
	.title-status-bar {
		display: none !important;
	}
	.btn-panels {
		flex-wrap: wrap !important;
		justify-content: center !important;
	}
	.btn-panels.services-moneyIn {
		top: 180px !important;
		flex-wrap: nowrap !important;
	}
	.cards-container.services-moneyIn {
		padding-top: 50px !important;
	}
	.rounded-pill-no-label {
		color: transparent !important;
		min-width: 22px !important;
		width: 22px !important;
	}
	.btn-panels.services-moneyOut {
		flex-wrap: nowrap !important;
		top: 190px !important;
	}
	.cards-container.services-moneyOut {
		padding-top: 70px !important;
	}
	.panel-Services {
		top: 50px !important;
	}
	.margin-auto-screem-sm {
		margin: auto !important;
	}
}
@media screen and (max-width: 368px) {
	.cards-details {
		padding: 40px 10px 32px 10px !important;
	}
}
@media screen and (max-width: 355px) {
	.textField {
		width: 100% !important;
	}
}
@media screen and (max-width: 331px) {
	.btn-panels.services-moneyIn {
		top: 200px !important;
		padding-top: 20px !important;
	}
}
