  .mainSignup {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .sectionSignup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    height: 100%;
    padding: 50px;
    position: relative;
  }

  .vectorTop {
    position: absolute;
    top: 0;
    right: -44px;
    width: 100%;
  }

  .vectorBottom {
    position: absolute;
    bottom: 0;
    left: -44px;
    width: 100%;
  }

  .signupBranding {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: 475px;
    z-index: 5;
    min-height: 52px;
  }

  .signupBranding img {
    max-width: 180px;
  }


  .signupContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    z-index: 5;
    max-width: 475px;
  }

  .signupFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 23.5px;
    z-index: 5;
    max-width: 475px;
  }


  .signupFooterHavingProblem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .signupFooterHavingProblem p {
    font-weight: 400;
    line-height: 17px;
    color: #999999;
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  .signupFooterHavingProblem span {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    margin: 0;
  }

  .signupFooterHavingProblem span a {
    font-weight: 700;
    color: #3D85C6 !important;
    font-size: 14px;
    text-decoration: underline !important;
    cursor: pointer;
  }

  .signupFooterLastBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
  }

  .signupFooterLastBlock > div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .signupFooterLastBlock > div img{
    width: auto;
    height: 32px;
  }

  .signupFooterLastBlock small {
    font-weight: 400;
    color: #999999;
    padding-block: 10px;
    font-size: 10px;
    text-align: center;
  }

  .icon {
    width: 48px;
    height: auto;
    aspect-ratio: 1/1;
  }

  .successTitle {
    font-weight: 500;
    line-height: 24px;
    color: #334155;
    font-size: 20px;
    text-align: center;
  }

  .errorTitle {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    font-size: 16px;
    text-align: center;
  }

  .notfoundTitle {
    color: #475569;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  .notfoundParagraph {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #64748B;
    margin: 0;
    display: flex;
    align-items: center;
    line-height: 0;
    gap: 4px;
  }

  .notfoundParagraph span {
    background: #FECACA;
    font-weight: 500;
    color: #7F1D1D;
    font-size: 12px;
    border-radius: 3px;
    padding: 4px;
  }


  @media (min-width: 640px) {

    .vectorTop {
      width: 40%;
      height: auto;
    }

    .vectorBottom {
      width: 40%;
      height: auto;
    }

    .errorTitle {
      line-height: 32px;
      font-size: 20px;
    }

    .successTitle {
      line-height: 26px;
      font-size: 24px;
    }

  }